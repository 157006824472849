<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="handleModal"
        >
            添加商品
        </Button>

        <Form
            class="search-form"
            ref="searchItem"
            inline
            :model="searchItem"
            @keydown.native.enter.prevent
        >
            <Form-item prop="goodsClass">
                <Select
                    v-model="searchItem.goodsClass"
                    class="search-select"
                    clearable
                    placeholder="请选择商品分类"
                    @on-change="onSearch"
                >
                    <Option
                        v-for="item in goodsClass"
                        :value="item.name"
                        :key="item.id"
                    >{{ item.name }}</Option>
                </Select>
            </Form-item>

            <Form-item prop="goodsStatus">
                <Select
                    v-model="searchItem.goodsStatus"
                    class="search-select"
                    clearable
                    placeholder="请选择商品状态"
                    @on-change="onSearch"
                >
                    <Option
                        v-for="item in goodsStatusList"
                        :value="item.id"
                        :key="item.id"
                    >{{ item.text }}</Option>
                </Select>
            </Form-item>

            <Form-item prop="name">
                <Input
                    v-model="searchItem.name"
                    class="search-input"
                    clearable
                    search
                    enter-button="搜索"
                    placeholder="请输入商品名称"
                    @on-search="onSearch"
                    @on-clear="onSearch"
                />
            </Form-item>

            <Form-item>
                <Button
                    type="error"
                    long
                    @click="batchRemove"
                >
                    批量删除
                </Button>
            </Form-item>
        </Form>

        <Table border :context="self" :columns="columns" :data="list"/>

        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StoreGoodsList',
    components: {
    },
    data() {
        return {
            self: this,
            goodsClass: null,
            searchItem: {
                name: '',
                goodsClass: '',
                goodsStatus: ''
            },
            columns: [
                {
                    title: '全选',
                    key: 'choose',
                    width: 100,
                    align: 'center',
                    fixed: 'left',
                    renderHeader: h => {
                        return h('Checkbox', {
                            props: {
                                value: this.isCheck
                            },
                            on: {
                                'on-change': (value) => {
                                    this.isCheck = value;
                                    this.list = this.list.map(item => {
                                        item.isCheck = value;
                                        return item;
                                    });
                                }
                            },
                        }, `全选`)
                    },
                    render: (h, params) => {
                        return h('Checkbox', {
                            props: {
                                value: params.row.isCheck
                            },
                            on: {
                                'on-change': (value) => {
                                    this.list[params.index].isCheck = value;
                                    const listIndex = this.list.findIndex(item => item.isCheck === !value);
                                    if (listIndex < 0) {
                                        this.isCheck = value;
                                    }
                                }
                            },
                        })
                    }
                },
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '排序',
                    key: 'sort',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '商品图',
                    key: 'src',
                    width: 110,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '商品名称',
                    key: 'name',
                    minWidth: 190,
                    align: 'center'
                },
                {
                    title: '商品现价',
                    key: 'memberPrice',
                    width: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'list-member-price'
                        }, `${params.row.memberPrice}元`)
                    }
                },
                {
                    title: '商品原价',
                    key: 'price',
                    width: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, `${params.row.price}元`)
                    }
                },
                {
                    title: '商品库存',
                    key: 'goodsStock',
                    width: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: `${+params.row.goodsStock === 0 ? 'goods-red' : ''}`
                        }, `${params.row.goodsStock}件`)
                    }
                },
                // {
                //     title: '商品分类',
                //     key: 'goodsClass',
                //     minWidth: 150,
                //     align: 'center',
                //     render: (h, params) => {
                //         return h('div', {
                //         }, params.row.goodsClass.join(' / '));
                //     }
                // },
                {
                    title: '抢购时间',
                    key: 'goodsDate',
                    width: 160,
                    align: 'center',
                    render: (h, params) => {
                        const newTime = new Date().getTime();
                        const endTime = new Date(params.row.goodsDate[1]).getTime();
                        return h('div', {
                            class: `${newTime > endTime ? 'goods-red' : ''}`
                        }, params.row.goodsDate.length === 1 ? '不限' : [
                            h('span', {
                                class: 'goods-date',
                            }, `开始: ${params.row.goodsDate[0]}`),
                            h('span', {
                                class: 'goods-date',
                            }, `结束: ${params.row.goodsDate[1]}`)
                        ]);
                    }
                },
                // {
                //     title: '商品销量',
                //     key: 'salesVolume',
                //     width: 120,
                //     align: 'center',
                //     render: (h, params) => {
                //         return h('span', {
                //             class: `${+params.row.salesVolume === 0 ? 'goods-red' : ''}`
                //         }, `${params.row.salesVolume}件`)
                //     }
                // },
                {
                    title: '审核状态',
                    key: 'shenhe',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        const {shenhe} = params.row;
                        return h('Button', {
                            props: {
                                type: `${shenhe ? (shenhe === '1' ? 'success' : 'error') : 'primary'}`,
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.setShenen(params.row, params.index);
                                }
                            }
                        }, `${shenhe ? (shenhe === '1' ? '已通过' : '已拒绝') : '未审核'}`)
                    }
                },
                {
                    title: '商品状态',
                    key: 'goodsStatus',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: `${params.row.goodsStatus === '1' ? 'ivu-btn-success' : 'ivu-btn-error'} ivu-btn-small class-btn`,
                            on: {
                                click: () => {
                                    this.goodsStatus(params.row, params.index);
                                }
                            }
                        }, `${params.row.goodsStatus === '1' ? '已上架' : '已下架'}`)
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.row);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            isCheck: false,
            goodsStatusList: [
                {
                    id: '-1',
                    text: '全部'
                },
                {
                    id: '1',
                    text: '已上架'
                },
                {
                    id: '0',
                    text: '已下架'
                }
            ]
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            handler(storeData) {
                if (!storeData) return;

                // 获取商品分类(二级导航)
                this.$axios({
                    url: '/api/secNav/goods/list',
                    method: 'post',
                    data: {
                        navs: storeData.type
                    }
                }).then(res => {
                    this.goodsClass = res.data.list;
                });

                this.getData(this.pn);
            }
        }
    },
    mounted() {
    },
    methods: {
        handleModal() {
            this.$router.push({
                path: '/storeGoods/addStoreGoods',
                query: {
                    id: this.getStoreData.id,
                    isStore: 1
                }
            });
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/goods/getGoodsList',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn,
                    storeId: this.getStoreData.id
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        edit(data) {
            this.$router.push({
                path: '/storeGoods/addStoreGoods',
                query: {
                    id: this.getStoreData.id,
                    goodsId: data.id,
                    isStore: 1
                }
            });
        },
        
        // 删除当前分类
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/goods/deleteGoods',
                        method: 'post',
                        data: {
                            id: this.list[index].id
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        onSearch() {
            this.pn = 0;
            this.getData(this.pn);
        },

        // 批量删除
        batchRemove() {
            if (this.list.findIndex(item => item.isCheck === true) < 0) {
                this.$Message.warning('请选择商品');
                return;
            }

            const cacheList = [];
            for (let i=0; i<this.list.length; i++) {
                let item = this.list[i];
                if (!item.isCheck || item.goodsStatus === status) continue;

                cacheList.push({
                    id: item.id
                });
            }
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/goods/deleteGoods',
                        method: 'post',
                        data: {
                            list: cacheList
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.isCheck = false;
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        setShenen(data, index) {
            const {id, shenhe} = data;
            this.$Modal.confirm({
                title: '商品审核',
                content: '请对商品进行审核',
                closable: true,
                okText: `${!shenhe || shenhe === '2' ? '通过' : '拒绝'}`,
                cancelText: `${shenhe ? '取消' : '拒绝'}`,
                onOk: () => {
                    const shenheValue = shenhe === '1' ? '2' : '1';
                    this.$axios({
                        url: '/api/goods/updataShenhe',
                        method: 'post',
                        data: {
                            id,
                            shenhe: shenheValue
                        }
                    }).then(res => {
                        this.list[index].shenhe = shenheValue;
                        this.isCheck = false;
                        this.$Message.success(res.data.message);
                    }).catch(() => {});
                },
                onCancel: () => {
                    if (shenhe) {
                        this.isCheck = false;
                    }
                    else {
                        this.$axios({
                            url: '/api/goods/updataShenhe',
                            method: 'post',
                            data: {
                                id,
                                shenhe: '2'
                            }
                        }).then(res => {
                            this.list[index].shenhe = '2';
                            this.isCheck = false;
                            this.$Message.success(res.data.message);
                        }).catch(() => {});
                    }
                }
            });
        },
        goodsStatus(data, index) {
            let tips = '确定要下架当前商品吗';
            let goodsStatus = '0';
            if (data.goodsStatus === '0') {
                tips = '确定要上架当前商品吗';
                goodsStatus = '1';
            }
            this.$Modal.confirm({
                title: '提示',
                content: tips,
                onOk: () => {
                    this.$axios({
                        url: '/api/goods/updataGoodsStatus',
                        method: 'post',
                        data: {
                            id: data.id,
                            goodsStatus
                        }
                    }).then(res => {
                        this.list[index].goodsStatus = goodsStatus;
                        this.$Message.success(res.data.message);
                    }).catch(() => {});
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .add-form {
        margin-top: 20px;

        .setup-radio {
            margin-right: 20px;
        }
    }
    .modal-footer {
        text-align: right;
    }
    .add-submit {
        width: 100px;
    }
    .search-form {
        padding-top: 8px;
    }
    .search-select {
        width: 150px;
    }
    .search-input {
        width: 250px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .goods-btns {
        padding-bottom: 10px;
    }
    .goods-btns::after {
        content: " ";
        display: block;
        clear: both;
    }
    .batch-submit {
        width: 100px;
        margin-right: 10px;
    }
    ::v-deep {
        .goods-date {
            display: block;
        }
        .class-btn {
            margin: 0 5px;
        }
        .list-photo {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .class-btn {
            margin: 0 5px;
        }
        .list-member-price {
            color: #f65b1a;
            font-weight: bold;
        }
        .goods-red {
            color: #f00;
        }
        .goods-blue {
            color: #2d8cf0;
        }
    }
</style>
